import React from "react";
import FunctionPageLayout from "../components/functionPageLayout";
import { graphql } from "gatsby";
import Lightbox from "../components/lightBox";
import SEO from "../components/seo";
import Container from "../components/container";
import Columns from "../components/columns";

export const query = graphql`
  query {
    panel: file(relativePath: { eq: "pulpitkontrahenta.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const PanelKlienta = ({ data }) => {
  return (
    <FunctionPageLayout
      title="Panel kontrahenta"
      headline="Rejestrowanie zleceń online"
    >
      <SEO
        title="Panel kontrahenta"
        description="Panel klienta w systemie dla agencji reklamowej i marketingowej • Rejestrowanie zleceń online • Zapytaj o bezpłatne demo!"
        keywords={["panel kontrahenta"]}
      />
      <Container className="container container--text">
        <Columns>
          <div className="text-answers">
            <div className="text-block">
              <p>
                <strong>Panel klienta</strong> to wygodne i proste w obsłudze{" "}
                <strong>narzędzie do rejestrowania zleceń online. </strong>
                Klienci Twojej agencji reklamowej zyskają możliwość tworzenia
                zgłoszeń w wygodnym dla nich momencie, za pośrednictwem
                dowolnego urządzenia łączącego się z Internetem. W dedykowanym
                im panelu{" "}
                <strong>
                  klienci sprawdzą również status swoich zleceń oraz przejrzą
                  dokumenty
                </strong>{" "}
                i ustalenia poczynione z Twoją firmą.
              </p>
            </div>
            <div className="text-block">
              <Lightbox
                style={{
                  maxWidth: 800,
                  margin: "0 auto",
                }}
                images={[data.panel]}
                alts={[
                  "Możliwość samodzielnego składania zleceń przez klientów w systemie dla agencji reklamowej",
                ]}
              />
            </div>
          </div>
        </Columns>
      </Container>
      <Container>
        <Columns>
          <div className="text-answers">
            <div className="text-block">
              <p>
                Jedną z korzyści jaką niesie ze sobą panel klienta jest{" "}
                <strong>samoobsługowe rejestrowanie zleceń</strong> przez Twoich
                klientów. Mogą oni przesyłać proste zlecenia (na przykład na
                seryjne gadżety reklamowe) bez konieczności odrywania handlowców
                od wykonywanych działań.
              </p>
              <p
                style={{
                  marginTop: "30px",
                }}
              >
                To rozwiązanie sprawdzi się szczególnie w przypadku{" "}
                <strong>klientów stałych</strong>, o ciągłym zapotrzebowaniu na{" "}
                <strong>gadżety lub materiały reklamowe</strong>. Dzięki
                panelowi klienta nie tylko{" "}
                <strong>podniesiesz jakość swoich usług</strong>, ale również{" "}
                <strong>obniżysz ich koszt</strong>. Panel klienta to także
                pewność. Wszystkie ustalenia z klientem są zapisywane w systemie
                i łatwo wyszukiwalne.
              </p>
            </div>
          </div>
        </Columns>
      </Container>
    </FunctionPageLayout>
  );
};

export default PanelKlienta;
